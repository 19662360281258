exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donate-result-js": () => import("./../../../src/pages/donate/result.js" /* webpackChunkName: "component---src-pages-donate-result-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-templates-category-projects-js": () => import("./../../../src/templates/CategoryProjects.js" /* webpackChunkName: "component---src-templates-category-projects-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-list-js": () => import("./../../../src/templates/ProjectsList.js" /* webpackChunkName: "component---src-templates-projects-list-js" */)
}

